import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
//Required imports
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import LoadingIndicator from "./LoadingIndicator";


//Lazy imports
const LaddingPage = lazy(() => import("./Pages/UserPages/LaddingPage.jsx"));
const LoginPage = lazy(() => import("./Pages/UserAuthPages/LoginPage.jsx"));
const Forgot = lazy(() => import("./Pages/UserAuthPages/forgot-password.jsx"));
const VerifyCode = lazy(() => import("./Pages/UserAuthPages/VerifyCode.jsx"));
const NewPassword = lazy(() => import("./Pages/UserAuthPages/NewPasswordPage.jsx"));
const RegistrationPage = lazy(() =>
  import("./Pages/UserAuthPages/RegistrationPage.jsx")
);
const RegistrationRecruteur = lazy(() =>
  import("./Pages/UserAuthPages/AddRecruiter.jsx")
);
const Form = lazy(() =>
  import("./Pages/UserPages/CreateResumeInitialQuestions/Form.jsx")
);
const CreateResumePage = lazy(() =>
  import("./Pages/UserPages/CreateResumePage.jsx")
);
const ModifyResumePage= lazy(() =>
  import("./Pages/UserPages/ModifyResumePage.jsx")
);
const Offre = lazy(() => import("./Pages/UserPages/Offre.jsx"));
const FAQPage = lazy(() => import("./Pages/UserPages/FAQPage.jsx"));
const AboutPage = lazy(() => import("./Pages/UserPages/AboutPage.jsx"));
const JobFinder = lazy(() => import("./Pages/UserPages/JobFinder.jsx"));
const Cvs = lazy(() => import("./Pages/UserPages/CvList.jsx"));


const AdminDashBoard = lazy(() =>
  import("./Pages/AdminPage/AdminDashBoard.jsx")
);
const RecruiterDashboard = lazy(() =>
  import("./Pages/RecruiterPage/RecruiterDashboard.jsx")
);

const router = createBrowserRouter([
  { path: "/", element: <LaddingPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/register", element: <RegistrationPage /> },
  { path: "/registerrecruteur", element: <RegistrationRecruteur /> },
  { path: "/create-resume", element: <Form /> },
  { path: "/create-resume-page", element: <CreateResumePage /> },
  { path: "/offre", element: <Offre /> },
  { path: "/faq", element: <FAQPage /> },
  { path: "/about", element: <AboutPage /> },
  { path: "/jobs", element: <JobFinder /> },
  { path: "/admin-dashboard", element: <AdminDashBoard /> },
  { path: "/recruiter-dashboard", element: <RecruiterDashboard /> },
  { path: "/CvList", element: <Cvs/> },
  { path: "/verify-code", element: <VerifyCode/> },
  { path: "/new-password", element: <NewPassword/> },
  { path: "/forgot-password", element: <Forgot/> },
  { path:"/modify-resume" , element: <ModifyResumePage/>}
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Suspense fallback={<LoadingIndicator />}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);
